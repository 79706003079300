const svg = createSvgBackground();
const svgElement = document.querySelector('#svg-container svg');

const stdRound = 120; // Standardwert für 'round' (Radius des Biegeradius)
const colorForDot = "#daa520"; // Farbe für den hervorgehobenen Punkt
const colorForDashedLine = "#848383"; // Farbe für die gestrichelte Linie
const wait = 500; // Wartezeit in Millisekunden vor dem Zeichnen des Pfads
const mobileWidth = 767; // Breite, ab der der Pfad nicht mehr gezeichnet wird

function createSetup() {
    const children = Array.from(document.body.children).filter((child) => {
        return (
            child.tagName.toLowerCase() !== "script" &&
            child.tagName.toLowerCase() !== "style"
        );
    });

    if (children.length > 0) {
        children.forEach((child) => {
            if (child) {
                Object.assign(child.style, {
                    position: "relative",
                    "z-index": 2
                });
            }
        });
    }
}

function createSvgBackground() {
    const svgContainer = document.getElementById('svg-container');
    svgContainer.style.position = 'relative';

    const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svgElement.setAttribute("width", "100%");
    svgElement.setAttribute("height", "100%");

    Object.assign(svgElement.style, {
        position: "absolute",
        top: "0",
        left: "0",
        "pointer-events": "none",
        "user-select": "none",
    });

    svgContainer.appendChild(svgElement);

    return svgElement;
}


const svgTargets = document.querySelectorAll(".svg-target");
const padding = 30;

function checkSize() {
    const svgElement = document.getElementById('svg-container').querySelector('svg');
    if (window.innerWidth <= mobileWidth) {
        svgElement.style.display = 'none';
    } else {
        svgElement.style.display = 'block';

    }
}

createSvgPath();
createSvgPathDot();

function triggerMinimalScroll() {
    if (document.querySelectorAll('.alert-danger').length > 0) {
        return;
    }
    
    // Wenn bereits oben, Scroll nicht durchführen
    if (window.scrollY === 0) {
        return;
    }

    const currentScrollY = window.scrollY;
    const maxScrollY = document.documentElement.scrollHeight - window.innerHeight;

    window.scrollTo({
        top: currentScrollY + (currentScrollY >= maxScrollY ? -1 : 1),
        behavior: 'auto'
    });

    setTimeout(() => {
        window.scrollTo({
            top: currentScrollY,
            behavior: 'auto'
        });
    }, 20);
}

document.addEventListener('DOMContentLoaded', function () {
    setTimeout(function () {
        checkSize();
        svg.innerHTML = "";
        if ((window.innerWidth <= mobileWidth)) return;
        if (!svgElement) return;
        if (document.querySelectorAll('.svg-target').length <= 0) {
            return;
        }
        createSvgPath();
        createSvgPathDot();
        triggerMinimalScroll();
    }, wait);
});

window.addEventListener("resize", () => {
    checkSize();
    svg.innerHTML = "";
    if ((window.innerWidth <= mobileWidth)) return;
    if (!svgElement) return;
    createSvgPath();
    createSvgPathDot();
    triggerMinimalScroll();
});

function createSvgPath() {
    if ((window.innerWidth <= mobileWidth)) return;
    if (!svgElement) return;
    let d = ""; // Pfad-String, der am Ende gesetzt wird
    let prevX = 0;
    let prevY = 0;
    let rounds = []; // Hier speichern wir die 'round' Werte

    // 1. Schritt: Vorwärts-Durchlauf um 'round' Werte initial zu bestimmen
    svgTargets.forEach((target, index) => {
        const {x, y, height} = target.getBoundingClientRect();
        let xPos = x + window.scrollX - padding;
        let yPos = y + window.scrollY + height / 2;

        if (index === 0) {
            rounds.push(stdRound); // Erster Punkt bekommt Standardwert
        } else {
            let round = stdRound; // Standardwert für 'round'
            const horizontalDistance = Math.abs(xPos - prevX);
            if (horizontalDistance < stdRound * 2) {
                round = horizontalDistance / 2.5;
            }
            rounds.push(round);
        }

        prevX = xPos; // Setze prevX für den nächsten Punkt
        prevY = yPos;
    });

    // 2. Schritt: Rückwärts-Durchlauf um 'round' Werte anzupassen
    for (let i = svgTargets.length - 1; i > 0; i--) {
        const {x: prevX} = svgTargets[i - 1].getBoundingClientRect();
        let xPos = svgTargets[i].getBoundingClientRect().x + window.scrollX - padding;

        const horizontalDistance = Math.abs(xPos - prevX);
        //if (horizontalDistance < 2050) {
        // Wenn der horizontale Abstand klein ist, passe den Radius des vorherigen Segments an
        rounds[i - 1] = Math.min(rounds[i - 1], stdRound - horizontalDistance);
        //}
    }

    // Verwende das 'rounds' Array, um den Pfad zu zeichnen
    svgTargets.forEach((target, index) => {
        const {x, y, height} = target.getBoundingClientRect();
        let xPos = x + window.scrollX - padding;
        let yPos = y + window.scrollY + height / 2.5;
        let round = Math.max(rounds[index], 0);
        if (index === 0) {
            d += `M ${xPos} ${yPos}`; // Beginne den Pfad
        } else {
            const midY = (yPos + prevY) / 2;
            if (xPos < prevX) {
                // Punkt liegt links vom vorherigen Punkt
                d += `L ${prevX} ${midY - round}`;
                d += `Q ${prevX} ${midY}, ${prevX - round} ${midY}`;
                d += `L ${xPos + round} ${midY}`;
                d += `Q ${xPos} ${midY}, ${xPos} ${midY + round}`;
            } else {
                // Punkt liegt rechts vom vorherigen Punkt
                d += `L ${prevX} ${midY - round}`;
                d += `Q ${prevX} ${midY}, ${prevX + round} ${midY}`;
                d += `L ${xPos - round} ${midY}`;
                d += `Q ${xPos} ${midY}, ${xPos} ${midY + round}`;
            }
        }
        prevX = xPos; // Aktualisiere die vorherige X-Position
        prevY = yPos; // Aktualisiere die vorherige Y-Position
    });

    if (svgTargets.length > 0) {
        const lastTarget = svgTargets[svgTargets.length - 1];
        const {x, y, height} = lastTarget.getBoundingClientRect();
        let lastXPos = x + window.scrollX - padding;
        let lastYPos = y + window.scrollY + height / 2;

        // Stelle sicher, dass der Pfad den letzten Punkt erreicht, indem du ein L oder Q SVG-Kommando hinzufügst
        d += `L ${lastXPos} ${lastYPos}`;
    }

    // Zeichne den Pfad auf der Seite
    createPath(d);
    animatePath(createPath(d, true));
}

// hier wird der parallax effect abgefangen und behandelt,
function animatePath(path) {
    let debounceTimer;
    let scrollCounter = 0; // Zähler für Scroll-Events
    let marginBottom = parseInt(window.getComputedStyle(document.body).marginBottom, 10) || 0; // Erstmaligen Wert abrufen
    const length = path.getTotalLength();
    path.style.strokeDasharray = length;
    path.style.strokeDashoffset = length;

    function handleScroll() {
        if (window.innerWidth <= mobileWidth) return; // Keine Aktion, wenn unterhalb der mobilen Breite
        if (!svgElement) return; // Keine Aktion, wenn das SVG-Element nicht vorhanden ist

        if (!debounceTimer) {
            debounceTimer = setTimeout(function () {
                // MarginBottom nur alle 10 Scroll-Events aktualisieren
                if (scrollCounter < 10) {
                    marginBottom = parseInt(window.getComputedStyle(document.body).marginBottom, 10) || 0;
                    scrollCounter++;
                    if (marginBottom > 0) scrollCounter = 1000; // Zähler hochsetzen, um weitere Aktualisierungen zu stoppen
                }

                try {
                    const {scrollTop, scrollHeight, clientHeight} = document.documentElement;
                    const height = scrollHeight - clientHeight - marginBottom; // Verwende den abgefragten Wert
                    const scrollPercent = scrollTop / height;
                    const progression = length * scrollPercent;
                    path.style.strokeDashoffset = Math.max(length - progression, 0);
                } catch (error) {
                    //console.error("Fehler während der Scroll-Event-Verarbeitung:", error);
                }

                debounceTimer = null; // Timer zurücksetzen
            }, 1); // Verzögerungszeit von 10ms
        }
    }

    window.addEventListener("scroll", handleScroll);
}


// Initialisiere die Animation, wenn die Seite vollständig geladen ist
window.addEventListener('DOMContentLoaded', (event) => {
    try {
        if (typeof d === 'undefined') {
            //console.error("Die Variable 'd' ist nicht definiert.");
            return; // Beendet die Funktion frühzeitig, wenn 'd' nicht definiert ist
        }
        const pathElement = createPath(d, true);
        animatePath(pathElement);
    } catch (error) {
        //console.error("Fehler beim DOMContentLoaded Event:", error);
    }
});


function createPath(d, white) {
    if ((window.innerWidth <= mobileWidth)) return;
    if (!svgElement) return;
    let path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    path.setAttribute("d", d);
    path.setAttribute("fill", "none");
    path.setAttribute("stroke", white ? colorForDot : colorForDashedLine);
    path.setAttribute("stroke-width", "3");
    path.setAttribute("stroke-linejoin", "round");
    path.setAttribute("stroke-linecap", "round");
    path.setAttribute("stroke-dasharray", "1,10");
    svg.appendChild(path);
    return path;
}

function createSvgPathDot() {
    if ((window.innerWidth <= mobileWidth)) return;
    if (!svgElement) return;
    svgTargets.forEach((target) => {
        const {x, y, height} = target.getBoundingClientRect();
        const circle = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "circle"
        );
        const xPos = x + window.scrollX - padding;
        const yPos = y + window.scrollY + height / 2;

        circle.setAttribute("cx", xPos);
        circle.setAttribute("cy", yPos);
        circle.setAttribute("r", 5);

        const strokeColor = target.getAttribute('data-stroke-color');
        // Setze die Farbe und Sichtbarkeit basierend auf der Klasse und dem data-stroke-color Attribut
        if (target.classList.contains('svg-target-hidden')) {
            circle.setAttribute("fill", strokeColor);
            circle.style.opacity = "0"; // Unsichtbar wenn svg-target-hidden
            circle.setAttribute("stroke", strokeColor);
        } else {
            circle.setAttribute("fill", colorForDot);
            circle.setAttribute("stroke", strokeColor);
        }

        circle.setAttribute("stroke-width", "4");
        svg.appendChild(circle);
    });
}
